import React, { useState, Component } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Card from '../components/card'
import styled from 'styled-components'
import ServiceHero from '../components/service-hero'
import CTA from '../components/cta-bar'
import PhotoGalleryComponent from '../components/photo-gallery-component'
import { OPEN_CONTACT_MODAL } from '../consts'
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'
import ReviewsAllModalPopup from '../components/reviews-all-modal-popup'
import { X, ChevronLeft, ChevronRight } from 'react-feather'
import { getSrc } from 'gatsby-plugin-image'

import { useTextReplacement } from '../hooks/useTextReplacement'

import { TestimonialSection } from '../components/testimonial_video'
import { AreasServed } from '../components/areas-served'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { getSchemaSameAs } from '../helpers/schema-json'

import Popup from 'reactjs-popup'
import EstimateForm from '../components/estimate-form'
// Import css files

import parse, { domToReact } from 'html-react-parser'
import ProjectPostItem from '../components/project-post-item'

const MainContentContainer = styled.div`
  p {
    color: #4c4c4c;
  }
`

const WhiteBGSection = styled.section`
  background: #f9f9f9;
  padding: 2.5em 1em;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px 0px;
`

const ServiceTemplate = (props) => {
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)

  useTextReplacement()

  const pageData = props.data.contentfulService
  const { showAreasServed } = pageData
  const { citiesList } = props.data

  const TopBar = () => {
    //pageData has the data
    const topBar = pageData.topbar || []
    return topBar.map(({ id, title, cssClasses, content }) => (
      <section className={cssClasses}>
        {parse(content.content, options)}
      </section>
    ))
  }

  const SidebarContainer = styled.div``

  const SidebarColumn = () => {
    const sideBarCards = pageData.sidebar.map((sidebar, index) => {
      if (sidebar.__typename == 'ContentfulSidebar') {
        return <Card key={index} sidebar={sidebar} />
      } else if (sidebar.__typename == 'ContentfulSidebarCollection') {
        const innerCards = sidebar.sidebars.map((sidebar, index) => {
          return <Card key={'inner' + index} sidebar={sidebar} />
        })
        return innerCards
      } else return null
    })
    return sideBarCards
  }

  const options = {
    replace: ({ attribs, children, name, type, ...theRest }) => {
      if (!attribs) return
      if (
        type == 'tag' &&
        name == 'a' &&
        attribs.class.includes(OPEN_CONTACT_MODAL)
      ) {
        const ATagButton = ({ onClick }) => {
          return (
            <a
              style={{ borderWidth: '0' }}
              href={attribs.href}
              className="border-radius button-style-primary button-style-white-outline border-0 cursor-pointer primary cta-button"
              // onClick={(e) => {
              //   e.preventDefault()
              //   onClick(e)
              // }}
            >
              {domToReact(children, options)}
            </a>
          )
        }
        return (
          <>
            <ATagButton onClick={() => setOpen((o) => !o)} />
            <Popup open={open} modal closeOnDocumentClick onClose={closeModal}>
              {(close) => (
                <div className="p-2 block">
                  <button
                    style={{ borderWidth: '0', float: 'right' }}
                    className="cursor-pointer d-block border-radius mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                    onClick={() => {
                      close()
                    }}
                  >
                    <X color={'#333'} strokeWidth={'3'} className="d-block" />
                  </button>
                  <div className="p-3 py-4 block">
                    <h2 className="mb-1 popupheading">
                      Request A Free Estimate!
                    </h2>
                    <p
                      style={{
                        color: '#666',
                        lineHeight: '1.25',
                        fontSize: '.9em',
                      }}
                      className="mb-3 popupdesc"
                    >
                      Take the next step today! Fill out the form below to
                      request a free estimate and get professional advice.
                      There’s no cost and no obligation and we’ll be glad to
                      help clarify any part of the process at any time.
                    </p>
                    <EstimateForm
                      showServicesList={true}
                      showLabels={true}
                      showPlaceHolders={true}
                      pageLocation={'Not provided'}
                    />
                  </div>
                </div>
              )}
            </Popup>
          </>
        )
      }
    },
  }

  const { testimonials: testimonial_video_posts } =
    pageData.testimonialSection || { testimonials: [] }

  const schemaSameAs = getSchemaSameAs(props.data.site.siteMetadata.social)

  return (
    <>
      <Layout
        pageProps={props}
        footerLogoShowcase={pageData.footerLogoShowcase}
        customSchema
      >
        <SEO
          title={pageData.seoTitle}
          description={pageData.metaDescription}
          img={getSrc(pageData.heroImage.metaTagImage)}
          robots={pageData.metaRobotsContent}
        />
        <ServiceHero
          pageData={pageData}
          serviceTitle={pageData.serviceTitle}
          heroImage={pageData.heroImage}
          excerpt={pageData.excerpt}
          offerDisclaimer={
            pageData.offerDisclaimer ? pageData.offerDisclaimer : ''
          }
          specialOffer={pageData.specialOffer}
          pageProps={props}
          badges={pageData.FeaturedBadges}
        />

        {pageData.socialReviewsFullWidth &&
        pageData.socialReviewsFullWidth.slug ? (
          <SocialReviewsFullwidth
            modalLinkTo={`/reviews/${pageData.socialReviewsFullWidth.slug}/`}
          />
        ) : null}

        {pageData &&
        pageData.photoGallery &&
        pageData.photoGallery[0].photos ? (
          <WhiteBGSection className="photogallerysec">
            <PhotoGalleryComponent gallery={pageData.photoGallery} />
          </WhiteBGSection>
        ) : null}

        <TopBar />
        <TestimonialSection testimonial_video_posts={testimonial_video_posts} />

        <div className="container brand-padding-y pt-0 service-page">
          <div className="row">
            <MainContentContainer
              className={
                pageData.sidebar
                  ? 'col-12 col-md-8 is-service-page'
                  : 'col-12 is-service-page'
              }
            >
              {pageData && pageData.content && pageData.content.content ? (
                <div
                  dangerouslySetInnerHTML={{ __html: pageData.content.content }}
                />
              ) : (
                ''
              )}
            </MainContentContainer>
            {pageData.sidebar ? (
              <SidebarContainer className="col-12 col-md-4">
                {props.data.serviceProjects &&
                  props.data.serviceProjects.edges.length > 0 && (
                    <div className="mb-4">
                      <h3>Our Recent Projects</h3>
                      <div className="grid grid-cols-2 gap-3">
                        {props.data.serviceProjects.edges.map(({ node }) => (
                          <ProjectPostItem
                            {...node}
                            key={node.id}
                            page="projects"
                            author="USQC"
                            className="mb-3"
                          />
                        ))}
                      </div>
                    </div>
                  )}
                <SidebarColumn />
                {props.data.serviceRelatedBlogPosts &&
                  props.data.serviceRelatedBlogPosts.edges.length > 0 && (
                    <Card
                      sidebar={{
                        title: 'From The Blog',
                        content: { content: 'blog' },
                      }}
                    >
                      {props.data.serviceRelatedBlogPosts.edges.map(
                        ({ node }) => (
                          <BlogPostSideBarItem
                            {...node}
                            key={node.id}
                            author={node.author.name}
                            className="mb-3"
                          />
                        )
                      )}
                    </Card>
                  )}
              </SidebarContainer>
            ) : (
              ''
            )}
          </div>
        </div>
        {showAreasServed && (
          <AreasServed
            citiesList={citiesList.edges}
            serviceName={pageData.seoTitle}
            parentPageSlug={pageData.slug}
          />
        )}
        <CTA
          title={'Start Your ' + pageData.serviceTitle + ' Project Today!'}
          customTitle={pageData.footerCallToActionHeading}
          customDesc={pageData.footerCallToActionDesc}
          bgimage={pageData.footerCallToActionImg}
          serviceHeroImage={pageData.heroImage}
          btn={'Get Started!'}
          props={props}
        />
      </Layout>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
              {
                "@id": "https://www.usqualityconstruction.com#HomeAndConstructionBusiness",
                "@context": "https://www.schema.org",
                "@type": "HomeAndConstructionBusiness",
                "name": "US Quality Construction",
                "url": "https://www.usqualityconstruction.com/",
                "logo": "https://images.ctfassets.net/wz4em6cftltu/5Vxb1RwD4HFKk9TlwF1pUH/2148b23e58228b3a31134298faa5b447/us-quality-logo.svg",
                "image": "https://images.ctfassets.net/wz4em6cftltu/AwnJ0VXm7X3NduvKI6iwN/a9b82ab9de9d7f8dacf42d048c6cc01f/wood-siding.jpg",
                "description": "US Quality Construction is a Local Siding Contractor Specializing in Siding Replacement, Siding Installation, Vinyl Siding, Decks, Replacement Windows & More.",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "1104 Market St",
                    "addressLocality": "Sedalia",
                    "addressRegion": "MO",
                    "postalCode": "65301",
                    "addressCountry": "US"
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "40.741895",
                    "longitude": "-73.989308"
                },
                "hasMap": "https://business.google.com/n/416859512158267703/",
                "openingHours": "Mo, Tu, We, Th, Fr 10:00-17:00",
                "telephone": "+1 816-208-9558",
                "priceRange":"$$",  
                "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "sales",
                    "telephone": "+1 816-208-9558"
                }
            }
            `,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
            {
              "@context": "http://schema.org/",
              "@type": "service",
              "servicetype": "${pageData.serviceTitle}",
              "provider": {
                  "@type": "organization",
                  "name": "${props.data.site.siteMetadata.title}",
                  "url": "${
                    props.data.site.siteMetadata.siteUrl +
                    props.location.pathname
                  }",
                  "logo": "https://images.ctfassets.net/wz4em6cftltu/k3dicBOhCSc3einkPzkid/eb33b3bd57d726b53480f8208b03b8ca/usqc-thumb.jpg",
                  "image": "https://images.ctfassets.net/wz4em6cftltu/AwnJ0VXm7X3NduvKI6iwN/a9b82ab9de9d7f8dacf42d048c6cc01f/wood-siding.jpg",
                  "address": {
                      "@type": "postaladdress",
                      "addresslocality": "Kansas City",
                      "addressregion": "KS"
                  },
                  "contactpoint": {
                      "@type": "contactpoint",
                      "contacttype": "customer support",
                      "telephone": "${
                        props.data.site.siteMetadata.companyInfo.phone
                      }",
                      "email": "${
                        props.data.site.siteMetadata.companyInfo.email
                      }"
                  },
                  "sameas": [${schemaSameAs.map((link) => `"${link}"`)}]
              },
              "areaserved": [{
                  "@type": "city",
                  "name": "Kansas City",
                  "@id": "https://en.wikipedia.org/wiki/Kansas_City,_Kansas"
              }],
              "hasoffercatalog": {
                  "@type": "offercatalog",
                  "name": "${pageData.serviceTitle}",
                  "itemlistelement": [{
                      "@type": "offercatalog",
                      "name": "${pageData.serviceTitle.toLowerCase()} services",
                      "itemlistelement": [
                        ${(pageData.schemaItemOffered || []).map(
                          (item) =>
                            `{
                        "@type": "offer",
                        "itemoffered": {
                            "@type": "service",
                            "name": "${item}"
                        }
                    }`
                        )}]
                  }]
              }
          }
        `,
        }}
      />
    </>
  )
}

const BlogPostSideBarItem = ({
  title,
  slug,
  image,
  publishDate,
  author,
  page = 'blog',
  excerpt,
  className,
}) => {
  return (
    <Link
      to={'/' + page + '/' + slug}
      className="flex mb-4 space-x-4"
      title={title}
    >
      <div className="w-24 h-24 flex-basis">
        <GatsbyImage
          image={getImage(image)}
          alt={title}
          className="rounded-lg"
        />
      </div>
      <div className="flex-1">
        <p className="hover:text-usq-red pb-1 border-b block flex text-base md:text-sm font-semibold items-top mb-0 leading-tight text-black">
          {title}
        </p>
        <p className="text-xs text-gray-500 mb-0">
          {publishDate} by {author}{' '}
        </p>
      </div>
    </Link>
  )
}

export default ServiceTemplate

export const pageQuery = graphql`
  query serviceQuery(
    $slug: String!
    $categorySlug: String
    $relatedCategories: [String]
  ) {
    site {
      siteMetadata {
        title
        siteUrl
        companyInfo {
          phone
          email
        }
        social {
          guildquality
          facebook
          # yelp
          instagram
          youtube
          google
          # angies
          # linkedin
          # bbb
        }
      }
    }
    contentfulService(slug: { eq: $slug }) {
      id
      serviceTitle
      seoTitle
      metaDescription
      metaRobotsContent
      slug
      excerpt
      offerDisclaimer
      specialOffer
      socialReviewsFullWidth {
        id
        slug
      }
      showReputationawardsSection
      showFeaturedBenefitsSection
      showAreasServed
      featuredBenefits {
        featuredBenefits
      }
      featuredContent {
        featuredContent
      }
      content {
        content
      }
      photoGallery {
        id
        title
        description {
          description
        }
        photos {
          id
          title
          fixed(width: 300, height: 250) {
            width
            height
            src
            srcSet
          }
          fluid(maxWidth: 1600) {
            src
          }
        }
      }
      topbar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
      FeaturedBadges {
        title
        gatsbyImageData(width: 300)
      }
      footerLogoShowcase
      footerCallToActionHeading
      footerCallToActionDesc
      footerCallToActionImg {
        gatsbyImageData(width: 1800)
      }

      heroImage {
        gatsbyImageData(width: 1200, placeholder: BLURRED)
        metaTagImage: gatsbyImageData(width: 1200, height: 630)
      }
      callToAction
      testimonialSection {
        testimonials {
          id
          title
          projectReference {
            slug
          }
          video {
            id
            title
            file {
              url
              contentType
            }
          }
          thumbnail {
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }
      sidebar {
        __typename
        ... on ContentfulSidebar {
          id
          title
          content {
            id
            content
          }
          cssClasses
        }
        ... on ContentfulSidebarCollection {
          sidebars {
            id
            title
            content {
              id
              content
            }
            cssClasses
          }
        }
      }
      schemaItemOffered
    }
    citiesList: allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          slug
          zipCodes
          stateAbbreviation
        }
      }
    }
    serviceProjects: allContentfulProjects(
      filter: { category: { elemMatch: { slug: { in: $relatedCategories } } } }
      limit: 4
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          category {
            id
            name
            slug
          }
          publishDate: createdAt(formatString: "MMMM Do, YYYY")
          image: thumbnail {
            gatsbyImageData(
              height: 265
              width: 400
              aspectRatio: 1
              resizingBehavior: CROP
              cropFocus: CENTER
              quality: 70
              placeholder: NONE
            )
          }
          city
        }
      }
    }
    serviceRelatedBlogPosts: allContentfulBlogPost(
      filter: { category: { slug: { eq: $categorySlug } } }
      limit: 4
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          # tags
          image: heroImage {
            gatsbyImageData(
              height: 120
              width: 120
              aspectRatio: 1
              resizingBehavior: CROP
              cropFocus: CENTER
              quality: 70
              placeholder: NONE
            )
          }
          metaDescription
          author {
            name
          }
        }
      }
    }
  }
`
